.am-toast {
  position: fixed;
  width: 100%;
  z-index: 1999;
  font-size: 0.32rem;
  text-align: center;
}

.am-toast > span {
  max-width: 50%;
}

.am-toast.am-toast-mask {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  -webkit-transform: translateZ(0.013333rem);
  transform: translateZ(0.013333rem);
}

.am-toast.am-toast-nomask {
  position: fixed;
  max-width: 50%;
  width: auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translateZ(0.013333rem);
  transform: translateZ(0.013333rem);
}

.am-toast.am-toast-nomask .am-toast-notice {
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.am-toast-notice-content .am-toast-text {
  min-width: 1.6rem;
  border-radius: 0.08rem;
  color: #fff;
  background-color: rgba(58, 58, 58, 0.9);
  line-height: 1.5;
  padding: 0.24rem 0.4rem;
}

.am-toast-notice-content .am-toast-text.am-toast-text-icon {
  border-radius: 0.133333rem;
  padding: 0.4rem 0.4rem;
}

.am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  margin-top: 0.16rem;
}